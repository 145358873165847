.portfolio body {
  background-color: white;
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.portfolio-item {
    flex-basis: calc(20% - 10px);
    margin-bottom: 25px;
    margin-right: 35px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.portfolio-item:hover {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.portfolio-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.item-content {
    padding-left: 0.75em;
}

.flex-container {
    display: flex;
}

.flex-container .bullet {
    margin: 0 10px;
}

/* Explicitly style the spans to ensure consistency */
.flex-container .another-text,
.flex-container .description,
.flex-container .battery {
    font-size: 14px; /* Adjust as needed */
    color: #333; /* Adjust as needed */
    line-height: 1.5; /* Optional: Improves readability */
}

.flex-container .another-text strong,
.flex-container .description strong,
.flex-container .battery strong {
    font-weight: bold;
    color: #000; /* Ensure strong tags match */
}

/* Apply margin-bottom consistently */
.portfolio-item .description,
.portfolio-item .another-text,
.portfolio-item .battery {
    margin-bottom: 10px;
}

.portfolio-header {
    text-align: center;
}

.title {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.subtitle {
    font-size: 1.2em;
    margin-bottom: 1em;
}

.portfolio-item .city {
    font-weight: bold;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .portfolio-item {
        flex-basis: calc(75% - 10px);
        margin-right: 10px;
        margin-bottom: 25px;
    }

    .portfolio-item img {
        height: auto;
    }

    .portfolio-header {
        margin-top: 3%;
    }

    .title {
        font-size: 1.5em;
    }

    .subtitle {
        font-size: 1em;
    }

    .portfolio {
        padding: 10px;
        padding-top: 20px;
    }

    .portfolio-item .city {
        font-weight: bold;
        margin: 5px 0;
    }

    .flex-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .flex-container .bullet {
        display: none;
    }

    .flex-container .another-text,
    .flex-container .description,
    .flex-container .battery {
        margin-bottom: 2.5px;
        font-size: 12px; /* Adjust for mobile if needed */
    }

    .flex-container .another-text strong,
    .flex-container .description strong,
    .flex-container .battery strong {
        font-weight: bold;
        color: #000; /* Ensure strong tags match on mobile */
    }
}