.refer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  background-color: #f5f5f5;
}

.refer-content {
  max-width: 90%;
  /* width: 100%; */
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 769px) {
  .refer-container {
    padding: 1rem;

  }
}

@media (max-width: 768px) {
  .refer-container {
    margin-top: 1.5rem;
    padding-top: 1em;
    width: 100%;
  }

  .refer-content {
    padding: 1rem;
  }

  .refer-content h1 {
    font-size: 1.5rem;
  }
}