.full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* This ensures the image covers the div without distortion */
}

.content {
    padding: 10%;;
    /* text-align: center; */
}

@media (min-width: 769px) {
    .mobile-about-container {
        display: none;
    }

    .about-container {
        display: grid;
        grid-template-rows: 40vh 60vh 60vh 60vh 60vh;
        height: 280vh;
    }
    
    .block {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid black;
    }
    
    .block:last-child {
        margin-bottom: 0;  /* Remove margin from the last block */
    }
    
    .split > .half {
        flex: 1;
        height: 100%;
        border-left: 2px solid black;
    }
    
    .split > .half:first-child {
        border-left: none;
    }
    
    .about {
        font-size: 4em;
        text-align: center;
    }
    
    .text {
        font-size: 3.5em;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .subtext {
        font-size: 0.5em;  /* Smaller font size than the main text */
        padding-top: 10px;  /* Padding at the top for spacing */
    }
    
    .content {
        padding: 10%;;
        /* text-align: center; */
    }
    
}



/* Media queries for responsiveness */
@media (max-width: 768px) {
    .about-container {
        display: none;
    }

    .mobile-about-container {
        display: grid;
        grid-template-rows: 40vh 60vh 60vh 60vh;
        /* height: 200vh; */
    }

    .mobile-block {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid black;
    }

    .about {
        font-size: 2.75em;
        text-align: center;
    }

    .text {
        font-size: 2.5em;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .subtext {
        font-size: 0.5em;  /* Smaller font size than the main text */
        padding-top: 10px;  /* Padding at the top for spacing */
    }

    
}