.app-footer {
    padding: 10px;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 13px;
    height: auto; /* Adjust the height to auto to cater for the added content */
}

.footer-a {
    font-weight: bold;
    font-size: 14px;
}

.footer-a, .footer-email, .footer-phone, .footer-stuff {
    color: #fff;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
}

.footer-social-media {
    margin-top: 10px; /* Adjust as needed */
}

.footer-icon {
    color: #fff; /* Icon color */
    margin: 0 10px; /* Spacing between icons */
    font-size: 20px; /* Icon size */
}

.footer-icon:hover {
    color: #ddd; /* Hover color */
}


/* Mobile styles using a media query */
@media (max-width: 768px) { /* Adjust this value based on your mobile breakpoint */
    .app-footer {
        text-align: left; /* Align text to the left */
        padding-left: 20px; /* Add some padding to the left to prevent content from sticking to the edge */
    }
    
    .footer-contact {
        display: block; /* Show the contact details on mobile */
    }
}
