.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  background-color: #f5f5f5;
  padding: 1rem;
}

.maintenance-content {
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.maintenance-content h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.maintenance-content p {
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input,
textarea,
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

button[type="submit"] {
  background-color: #f79a00;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  width: 100%;
}

button[type="submit"]:hover {
  background-color: #e68a00;
}

@media (max-width: 600px) {
  .maintenance-container {
    margin-top: 1.5rem;
  }

  .maintenance-content {
    padding: 1rem;
    /* margin-top: -10vh; */
  }

  .maintenance-content h1 {
    font-size: 1.5rem;
  }
}