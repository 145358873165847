/* Main container */
.design-installation {
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 0;
  background-image: url('../media/sunset.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* Background styling */
.background-container {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-image: url('../media/sunset.jpg');
  background-size: cover;
  background-position: center;
}
/* Content styling */
.content-container {
  position: relative;
  z-index: 1;
  padding: 2rem 1.5rem;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.design-title {
  color: white;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.request-button {
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #f79a00;
}

.request-button:hover {
  text-decoration: underline;
}

.main-description {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.service-details {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 3rem;
}

.service-details h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.service-details ul {
  text-align: left;
  list-style: none;
  padding: 0;
}

.service-details li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.check-icon {
  color: #10b981; /* Green color */
  font-size: 1.2rem;
  margin-right: 0.75rem;
  font-weight: bold;
}

.highlighted {
  font-weight: 500;
}

.cta-button {
  background-color: #2563eb; /* Blue color */
  color: white;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #1d4ed8; /* Darker blue */
  transform: scale(1.05);
}

/* Services section */
.services-section {
  position: relative;
  z-index: 1;
  padding: 0 1.5rem 6rem;
  max-width: 1200px;
  margin: 0 auto;
}

.services-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.service-card {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  padding: 2rem;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: scale(1.03);
}

.service-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.service-card p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.image-container {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
}
.residential {
  background-image: url('../media/solar-residential.JPG'); 
}

.commercial {
  background-image: url('../media/solar-commercial.JPG');
}

/* Responsive design */
@media (min-width: 768px) {
  /* h1 {
    font-size: 3.5rem;
  } */
  
  .services-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 4rem;
  }
  
  .content-container {
    padding: 4rem 2rem;
  }
}

/* Mobile styles */
@media (max-width: 768px) {

  .design-installation {
    top: -5vh;
  }

  .content-container {
    padding-top: 10vh;
  }
  .design-title {
    font-size: 1.5rem;
  }

  .main-description {
    font-size: 1rem;
  }

  .service-details h2 {
    font-size: 1.25rem;
  }
}