.form-group label {
  font-weight: bold;
  margin-bottom: 2.5%;
}

.form-group input {
  border: 1px solid black;
  border-radius: 0;
  height: 3em;
}

.btn-primary {
  background-color: black;
  border-color: black;
}

.btn-primary:hover {
  background-color: orange;
  border-color: orange;
}

@media (min-width: 769px) {

.form {
  display: grid;
  height: 90vh;
}

.desktop-form-container {
  display: flex;
  justify-content: center; /* If you want to have space between the two containers */
  gap: 0px;
  margin-top: 3.5em;
  background-size: cover;
  min-height: 90%;
}


.side-container, .form-container {
  width: calc(45%); /* Adjusted width to account for margin */
  margin: 0 20px;
  padding: 3%;
}

.side-container h1 {
  margin-bottom: 40px;
  font-weight: bold;
}

.form-button {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}



.form-group {
  width: 100%; /* Set default width to 100% */
  margin-bottom: 4%;
}



.form-group input {
  border: 1px solid black;
  border-radius: 0;
  height: 3em;
}

.form-button input {
  border-radius: 0;
}


.info-text {
  font-size: 20px;
}

.form-group-row {
  display: flex;
  justify-content: space-between;
}

.form-group-row .form-group {
    width: 48%; /* Adjusts width when inside a row */
}

.mobile-form-container{
  display: none;
}

.checkbox-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
}



@media (max-width: 768px) {
  .mobile-form-container{
      display: block;
      padding: 20px;
      /* margin-top: 2em; */
      /* background-color: #f8f9fa; */
  }
  .mobile-side-container{
      margin-bottom: 3em;
  }
  .mobile-form-container-inner .form-group{
      margin-bottom: 15px;
  }

  .mobile-side-container h1 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .form-button{
      display: flex;
      justify-content: center;
  }
  .btn-primary{
      width: 100%;
  }

  .desktop-form-container{
    display: none;
  }

  .checkbox-container input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

