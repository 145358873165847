.full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* This ensures the image covers the div without distortion */
}

.content {
    padding: 10%;;
    /* text-align: center; */
}

@media (min-width: 769px) {
    .mobile-battery-container {
        display: none;
    }

    .block.centered-table {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%; /* Adjust height as needed */
      }
      
      .table-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .table-container table {
        /* Add your styles for the table here */
        border-collapse: collapse;
        width: 100%; /* Sets the table to fill the container */
        margin: 0; /* Removes default margin */
      }
      
      .table-container th,
      .table-container td {
        border: 1px solid #ddd; /* Example border for the cells */
        padding: 8px; /* Example padding */
        text-align: center; /* Centers text horizontally */
      }
      

    .battery-container {
        display: grid;
        grid-template-rows: 50vh 40vh 70vh 60vh;
        height: 220vh;
    }
    
    .block {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid black;
    }
    
    .block:last-child {
        margin-bottom: 0;  /* Remove margin from the last block */
    }
    
    .split > .half {
        flex: 1;
        height: 100%;
        border-left: 2px solid black;
    }
    
    .split > .half:first-child {
        border-left: none;
    }
    
    .battery {
        font-size: 4em;
        text-align: center;
    }
    
    .text {
        font-size: 3.5em;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .subtext {
        font-size: 0.4em;  /* Smaller font size than the main text */
        padding-top: 10px;  /* Padding at the top for spacing */
    }
    
    .content {
        padding: 5%;;
        /* text-align: center; */
        line-height: 1.25;
    }

    .large-text {
        font-weight: 500;
    }

    .middle-text {
        font-size: 0.8em;
        font-weight: 500;
    }

    .small-text {
        font-size: 0.3em;
        padding-top: 10px;
    }

    .triple-column-container {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid black;
    }
    
    .column {
        width: 33%;
        padding: 10px;
        text-align: center;
    }
    
    .column img {
        width: 100%; /* Makes image responsive and maintain aspect ratio */
        height: 40vh; /* Set a fixed height for all images */
        object-fit: cover; /* This will cover the area, maintaining aspect ratio without stretching the image */
      }
    
    .large {
        font-size: 24px;
        margin-top: 10px;
    }
    
    .small {
        font-size: 14px;
        margin-top: 5px;
    }
    
    
}



/* Media queries for responsiveness */
@media (max-width: 768px) {
    .battery-container {
        display: none;
    }

    .mobile-battery-container {
        display: grid;
        grid-template-rows: 40vh 60vh 60vh 60vh;
        /* height: 200vh; */
    }

    .mobile-block {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid black;
    }

    .battery {
        font-size: 2.75em;
        text-align: center;
    }

    .text {
        font-size: 2.5em;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .subtext {
        font-size: 0.5em;  /* Smaller font size than the main text */
        padding-top: 10px;  /* Padding at the top for spacing */
    }

    
}