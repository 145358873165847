@media (min-width: 769px) {
  .desktop-nav-container {
    height: 10vh;
    z-index: 100;
    /* border: 10px solid blue;  */
  }

  .internal-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1%;
    margin-left: 25%;
    margin-right: auto;
    padding-bottom: 1%;
  }

  .internal-container.white-background .maintenance-link a{
    color: black !important;
  }

  .internal-container.white-background .refer-link a{
    color: black !important;
  }

  .internal-container.white-background {
    color: black !important;
  }
  
  .internal-container.white-background a {
    color: black !important;
  }
  
  .internal-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .internal-container ul li a {
    color: white;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .internal-container ul li a:hover {
    text-decoration: underline;
  }

  .nav-logo {
    margin-left: 2%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    /* margin-top: 5px;   */
  }

  .nav-item {
    position: absolute;
    right: 5%;
  }
  
  .links {
    display: flex;
    align-items: center;
  }
  
  .links li {
    margin-left: 60px;
    cursor: pointer;
    transition: .3s;
  }
  
  .links li:last-child {
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #f79a00;
  }
  
  .links li:last-child:hover {
    text-decoration: underline;
  }
  
  .links li:not(:last-child):hover {
    text-decoration: underline;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    z-index: 10;
    padding-top: 15px;
  }
  
  
  .dropdown-content.show {
    display: block;
  }
  
  .dropdown-item:hover {
    color: orange;
  }

  img {
    width: auto;
    height: 40%;
  }
  
  .dropbtn .arrow {
    content: '';
    display: inline-block;
    border: solid black;
    border-width: 0 1px 1px 0;
    padding: 3px;
    transform: rotate(45deg);
    position: relative;
    top: -2px;
    margin-left: 8px;
    vertical-align: middle;
  }
  
  .dropdown.open .dropbtn .arrow {
    transform: rotate(-135deg);
    top: 3px;
  }

  .parent-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
  }


  .nav-container {
    /* The menu is hidden by default with a translate */
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-container.open {
    /* When open, the translate is removed */
    transform: translateX(0);
  }

  .mobile-nav-container {
    display: none;
  }
}

/* Small Devices*/
@media (max-width: 768px) {
  .desktop-nav-container {
    display: none;
  }

  .mobile-nav-container {
    position: relative;
    z-index: 10;
    /* display: flex; */
    /* justify-content: center; */
    /* text-align: center; */
  }

  .nav-logo-container {
    /* display: inline-block; */
    position: absolute; /* To keep it at the top even when scrolling */
    top: 10px;
    z-index: 12;  /* Optional. To ensure the logo stays above other elements */
    right: 50%; /* move the right edge of the element to the center of the parent */
    transform: translateX(50%);
  }

  .nav-logo-mobile {
      width: 100%;
      max-width: 150px;
  }

  .hamburger-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 10px;
    /* left: 95%; */
    /* margin-left: 50%; */
    right: 30px;
    height: 1.5rem;
    cursor: pointer;
    z-index: 30;
  }

  .hamburger-menu.white-background div{
    background-color: black !important;
}

.hamburger-menu.open.white-background div {
  background-color: white !important;
}

  
  .hamburger-menu div {
    width: 1.5rem;
    height: 0.15rem;
    background-color: white;
    transition: all 0.2s linear;
    position: relative;
  }
  
  .hamburger-menu.open div:nth-child(1) {
    transform: rotate(45deg) translateX(5px) translateY(6px);
  }
  
  .hamburger-menu.open div:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-menu.open div:nth-child(3) {
    transform: rotate(-45deg) translateX(5px) translateY(-6px);
  }

  .mobile-nav {
    position: absolute;
    top: 50px;
    /* margin-top: 10px; */
    color: white;
    text-align: right;
    padding-right: 30px;
    /* right: 30px; */
    width: 100%;
  }

  .mobile-nav li {
    list-style: none;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    padding-top: 5px;
  }


  .slide-in {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 90%; /* adjust as needed */
    background-color: black;
    transition: all 0.3s ease-in-out;
    z-index: 10;
}

.slide-out {
    position: fixed;
    top: 0;
    right: -70%; /* adjust as needed */
    height: 100%;
    width: 70%; /* adjust as needed */
    background-color: black;
    transition: all 0.3s ease-in-out;
    z-index: 10;
}


}

